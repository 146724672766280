import React, { useEffect, useState, useMemo } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
} from 'reactstrap';

import BreadCrumb from '../../Components/Common/BreadCrumb';
import ExportReport from '../../Components/Common/ExportReport';

import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';
import Filter, { getFilters } from '../../Components/Common/Filter';

import { createSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  getCustomers,
  getFilters as getFiltersFromApi,
} from '../../slices/customers/thunk';
import {
  formatDocument,
  formatDate,
  formatCurrency,
  formatCellphone,
} from '../../helpers/format';
import { isEmpty } from 'lodash';

import CreateDigitalWallet from './Create';
import EditDigitalWallet from './Edit';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Can } from '../../helpers/casl';
import { cleanDocument } from '../../helpers/functions';
import Search from '../../Components/Common/Search';
import useQsParams from '../../Components/Hooks/QueryString';
import ReactSelect from 'react-select';
import PresetsFilter from '../../Components/Common/PresetsFilter';
import CsvStatusEdit from './CsvStatusEdit';
import WalletsImporter from './WalletsImporter';

const DigitalWallets = ({ t, ...props }) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams, setSearchParamsAsObject } =
    useQsParams();

  const [createWalletModal, setCreateWalletModal] = useState(false);
  const [editWalletModal, setEditWalletModal] = useState(false);
  const [editWalletData, setEditWalletData] = useState({});
  const [bulkStatusEditModal, setBulkStatusEditModal] = useState(false);
  const [showCsvImportWalletsModal, setCsvImportWalletsModal] = useState(false);

  const [csvStatusEditModal, setCsvStatusEditModal] = useState(false);

  const customersState = createSelector(
    (state) => state.Customer.customers,
    (customers) => customers
  );

  const loadingState = createSelector(
    (state) => state.Customer.isLoading,
    (isLoading) => isLoading
  );

  const metaState = createSelector(
    (state) => state.Customer.meta,
    (meta) => meta
  );

  const meta = useSelector(metaState);
  const customers = useSelector(customersState);
  const isLoading = useSelector(loadingState);
  const walletFilters = useSelector(
    createSelector(
      ({ Customer }) => Customer.filters,
      (slice) => slice
    )
  );

  useEffect(() => {
    getData();
    dispatch(getFiltersFromApi());
  }, [dispatch, searchParams.toString()]);

  let originArray = [
    {
      label: t('wallet-origins.lead_capture'),
      value: 'lead_capture',
    },
    {
      label: t('wallet-origins.panel'),
      value: 'panel',
    },
    {
      label: t('wallet-origins.order'),
      value: 'order',
    },
  ];

  const filters = [
    {
      name: t('wallets.filters.cellphone'),
      field: 'cellphone',
      type: 'radio',
    },

    {
      name: t('wallets.filters.cellphone_verified'),
      field: 'cellphone_verified',
      type: 'radio',
    },

    {
      name: t('wallets.filters.email'),
      field: 'email',
      type: 'radio',
    },

    {
      name: t('wallets.filters.birthdate'),
      field: 'birthdate',
      type: 'radio',
    },

    {
      name: t('balance'),
      field: 'amount_available',
      type: 'range',
    },

    {
      field: 'date',
      name: t('created_at'),
      type: 'date',
    },

    {
      field: 'origin',
      name: t('order-origin'),
      type: 'select',
      values: originArray,
    },

    {
      field: 'seller_activation',
      name: t('wallets.filters.seller_activation'),
      type: 'select',
    },

    {
      field: 'store_activation',
      name: t('wallets.filters.store_activation'),
      type: 'select',
    },

    // {
    //   field: "amount_used",
    //   name: t("wallets.filters.amount_used"),
    //   type: "switch",
    // },

    // {
    //   field: "amount_expired",
    //   name: t("wallets.filters.amount_expired"),
    //   type: "radio",
    // },
  ];

  const statuses = [
    'active',
    'inactive',
    'blocked',
    'blocked_accumulation',
    'blocked_redeem',
  ];

  const getStatusBadgeClasses = (status) => {
    switch (status) {
      case 'active':
        return 'bg-success-subtle text-success';
      case 'inactive':
        return 'bg-warning-subtle text-warning';
      default:
        return 'bg-danger-subtle text-danger';
    }
  };

  const parsedFilters = getFilters(filters, [
    { field: 'document', type: 'like' },
    { field: 'name', type: 'like' },
    { field: 'status', type: 'in' },
    { field: 'orders_num', type: 'gte' },
    { field: 'incentives_num', type: 'gte' },
    { field: 'amount_expired', type: 'gte' },
    { field: 'amount_next_expiration', type: 'gte' },
    { field: 'amount_used', type: 'gte' },
    { field: 'cellphone_verified', type: 'eq-as-raw' },
  ]);

  const getData = () => {
    let params = { page: searchParams.get('page') ?? 1 };
    const appliedFilters = parsedFilters;

    if (!isEmpty(appliedFilters)) params.filters = appliedFilters;

    if (!appliedFilters?.status) {
      const IN = statuses;
      params.filters = { ...params?.filters, status: { in: IN } };
    }

    dispatch(getCustomers(params));
  };

  const columns = useMemo(
    () => [
      {
        Header: t('document'),
        accessor: 'document',
        Cell: (props) => (
          <Link
            to={`/carteiras/detalhes/${props.row.original.document}`}
            style={{ borderBottom: '1px solid lightgray' }}
          >
            {formatDocument(props.row.original.document, accountSettings)}
          </Link>
        ),
        reportProps: {
          formatter: {
            type: 'mask',
            properties: { custom: 'document' },
          },
        },
      },

      {
        Header: t('name'),
        accessor: 'name',
      },

      {
        Header: t('phone'),
        Cell: (props) => {
          const values = props.row.original;
          const cellphone = values?.cellphone;
          const isVerified = values?.cellphone_verified;

          const renderVerifiedBadge = (
            <>
              <i
                className="bx bxs-badge-check fs-5 text-success ms-1"
                style={{ cursor: 'help' }}
                id="verified_phone"
              />

              <UncontrolledTooltip target="verified_phone" placement="top">
                {t('verified-phone')}
              </UncontrolledTooltip>
            </>
          );

          return (
            <div className="d-flex align-items-center">
              {formatCellphone(cellphone)}
              {isVerified ? renderVerifiedBadge : null}
            </div>
          );
        },
        reportProps: {
          accessor: 'cellphone',
        },
      },

      {
        Header: t('email'),
        accessor: 'email',
        Cell: (value) => {
          const values = value.row.original;
          const email = values?.email;
          const isEmailVerified = values?.email_verified;

          const renderVerifiedBadge = (
            <>
              <i
                className="bx bxs-badge-check fs-5 text-success ms-1"
                style={{ cursor: 'help' }}
                id="verified_email"
              />

              <UncontrolledTooltip target="verified_email" placement="top">
                {t('verified-email')}
              </UncontrolledTooltip>
            </>
          );

          return (
            <div className="d-flex align-items-center">
              {email}
              {isEmailVerified ? renderVerifiedBadge : null}
            </div>
          );
        },
        reportProps: { accessor: 'email' },
      },

      {
        Header: t('wallets.report.date'),
        accessor: 'date',
        Cell: ({ value }) => (value ? formatDate(value, 'DD/MM/YYYY') : '-'),
        reportProps: {
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
          },
        },
      },

      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }) => {
          const cn = getStatusBadgeClasses(value);
          const text = t(`customer-wallet-status-${value}`);
          return <span className={`badge rounded-pill ${cn}`}>{text}</span>;
        },

        reportProps: {
          accessor: 'status',
          formatter: {
            type: 'enum',
            properties: {
              options: [
                {
                  key: 'active',
                  value: t('customer-wallet-status-active'),
                },
                {
                  key: 'inactive',
                  value: t('customer-wallet-status-inactive'),
                },
                {
                  key: 'blocked',
                  value: t('customer-wallet-status-blocked'),
                },
                {
                  key: 'blocked_redeem',
                  value: t('customer-wallet-status-blocked_redeem'),
                },
                {
                  key: 'blocked_accumulation',
                  value: t('customer-wallet-status-blocked_accumulation'),
                },
              ],
            },
          },
        },
      },

      {
        Header: t('origin'),
        accessor: 'origin',
        Cell: ({ value }) => (value ? t(`wallet-origins.${value}`) : '-'),
        reportProps: {
          accessor: 'origin',
          formatter: {
            type: 'enum',
            properties: {
              options: [
                {
                  key: 'lead-capture',
                  value: t('wallet-origins.lead-capture'),
                },
                {
                  key: 'lead_capture',
                  value: t('wallet-origins.lead_capture'),
                },
                {
                  key: 'panel',
                  value: t('wallet-origins.panel'),
                },
                {
                  key: 'incentive',
                  value: t('wallet-origins.incentive'),
                },
                {
                  key: 'order',
                  value: t('wallet-origins.order'),
                },
                {
                  key: 'import',
                  value: t('wallet-origins.import'),
                },
              ],
            },
          },
        },
      },

      {
        Header: t('balance'),
        accessor: 'amount_available',
        Cell: ({ value }) => formatCurrency(value),
        reportProps: {
          formatter: {
            type: 'currency',
            properties: { currency: 'BRL', centsToCurrency: true },
          },
        },
      },

      {
        Header: t('wallets.filters.orders_num'),
        accessor: 'orders_num',
      },

      {
        Header: t('wallets.filters.incentives_num'),
        accessor: 'incentives_num',
      },

      {
        Header: t('actions'),
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <Can I="read" a="customers">
                <li className="list-inline-item" title="View">
                  <Link
                    to={`/carteiras/detalhes/${cellProps.row.original.document}`}
                  >
                    <i className="ri-eye-fill align-bottom text-muted"></i>
                  </Link>
                </li>
              </Can>
              <Can I="update" a="customers">
                <li className="list-inline-item" title="Edit">
                  <Link
                    className="edit-item-btn"
                    onClick={() => {
                      setEditWalletData(cellProps.row.original);
                      setEditWalletModal(true);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom text-muted"></i>
                  </Link>
                </li>
              </Can>
            </ul>
          );
        },
        reportProps: {
          exclude: true,
        },
      },
    ],
    []
  );

  const reportsOnlyColumns = [
    {
      Header: t('wallets.report.cellphone_verified'),
      accessor: 'cellphone_verified',
      reportProps: {
        formatter: {
          type: 'boolean',
          properties: {
            option_true: t('wallets.report.cellphone_verified-true'),
            option_false: t('wallets.report.cellphone_verified-false'),
          },
        },
      },
    },
    {
      Header: t('wallets.report.email_verified'),
      accessor: 'email_verified',
      reportProps: {
        formatter: {
          type: 'boolean',
          properties: {
            option_true: t('wallets.report.email_verified-true'),
            option_false: t('wallets.report.email_verified-false'),
          },
        },
      },
    },
    {
      Header: t('wallets.report.orders_last_date'),
      accessor: 'orders_last_date',
      Cell: ({ value }) => (value ? formatDate(value, 'DD/MM/YYYY') : '-'),
      reportProps: {
        formatter: {
          type: 'date',
          properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
        },
      },
    },
    {
      Header: t('wallets.report.incentives_last_date'),
      accessor: 'incentives_last_date',
      Cell: ({ value }) => (value ? formatDate(value, 'DD/MM/YYYY') : '-'),
      reportProps: {
        formatter: {
          type: 'date',
          properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
        },
      },
    },
    {
      Header: t('wallets.report.orders_amount'),
      accessor: 'orders_amount',
      reportProps: {
        formatter: {
          type: 'currency',
          properties: { currency: 'BRL', centsToCurrency: true },
        },
      },
    },
    {
      Header: t('wallets.report.amount_received'),
      accessor: 'amount_received',
      Cell: ({ value }) => formatCurrency(value),
      reportProps: {
        formatter: {
          type: 'currency',
          properties: {
            currency: 'BRL',
            centsToCurrency: true,
          },
        },
      },
    },
    {
      Header: 'Saldo total resgatado',
      accessor: 'amount_used',
      Cell: ({ value }) => formatCurrency(value),
      reportProps: {
        formatter: {
          type: 'currency',
          properties: {
            currency: 'BRL',
            centsToCurrency: true,
          },
        },
      },
    },
    {
      Header: 'Saldo total expirado',
      accessor: 'amount_expired',
      Cell: ({ value }) => formatCurrency(value),
      reportProps: {
        formatter: {
          type: 'currency',
          properties: {
            currency: 'BRL',
            centsToCurrency: true,
          },
        },
      },
    },
    {
      Header: 'Loja de ativação',
      accessor: 'store_activation',
      Cell: ({ value }) => value ?? '-',
    },
    {
      Header: 'Vendedor de ativação',
      accessor: 'seller_activation',
      Cell: ({ value }) => value ?? '-',
    },
    {
      Header: 'Data da próxima expiração',
      accessor: 'amount_next_expiration_at',
      Cell: ({ value }) => (value ? formatDate(value, 'DD/MM/YYYY') : '-'),
      reportProps: {
        formatter: {
          type: 'date',
          properties: {
            tz: 'America/Sao_Paulo',
            format: 'DD/MM/YYYY',
          },
        },
      },
    },
    {
      Header: 'Valor da próxima expiração',
      accessor: 'amount_next_expiration',
      Cell: ({ value }) => formatCurrency(value),
      reportProps: {
        formatter: {
          type: 'currency',
          properties: {
            currency: 'BRL',
            centsToCurrency: true,
          },
        },
      },
    },
  ];

  const orderStatus = [
    {
      label: t('wallets.filters.status-active'),
      value: 'active',
    },
    {
      label: t('wallets.filters.status-inactive'),
      value: 'inactive',
    },
    {
      label: t('wallets.filters.status-blocked'),
      value: 'blocked',
    },
    {
      label: t('wallets.filters.status-blocked_accumulation'),
      value: 'blocked_accumulation',
    },
    {
      label: t('wallets.filters.status-blocked_redeem'),
      value: 'blocked_redeem',
    },
  ];

  const accountSettings = useSelector(
    (state) => state.GeneralSettings.accountSettings
  );

  document.title = `${t('wallets.menu')} | Opencashback`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            isLoading={isLoading}
            title={t('wallets.menu')}
            pageTitle="Gestão"
          />

          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 justify-content-between flex-column flex-xxl-row">
                    <Col xs={12} xxl={6} className="col-80-at-1700">
                      <Row className="g-3 row-cols-1 row-cols-lg-3">
                        <Col>
                          <Search
                            inputMode="numeric"
                            placeholder={t('document')}
                            value={formatDocument(
                              searchParams?.get('document'),
                              accountSettings
                            )}
                            onSubmit={({ id }) => {
                              setSearchParamsAsObject({
                                page: 1,
                                document: id ? _.trim(cleanDocument(id)) : '',
                              });
                            }}
                          />
                        </Col>

                        <Col>
                          <Search
                            placeholder={t('name')}
                            id="name"
                            value={searchParams?.get('name')}
                            onSubmit={({ id }) => {
                              setSearchParamsAsObject({
                                page: 1,
                                name: id ?? '',
                              });
                            }}
                          />
                        </Col>

                        <Col>
                          <ReactSelect
                            name="status"
                            isClearable
                            isSearchable
                            placeholder={t('status')}
                            onChange={(v) => {
                              setSearchParamsAsObject({
                                status: v?.value ?? '',
                                page: 1,
                              });
                            }}
                            value={orderStatus.find(
                              (x) => x.value === searchParams?.get('status')
                            )}
                            options={orderStatus}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col xxl="auto" className="transition">
                      <Row className="g-3 row-cols-1 row-cols-md-2 row-cols-lg-auto transition">
                        <Col>
                          <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={() => setSearchParams('showFilter', true)}
                            disabled={isLoading}
                          >
                            <i className="ri-filter-3-line align-bottom me-1" />
                            {t('advanced-filters')}
                          </button>
                        </Col>

                        <Can I="create" a="customers">
                          <Col>
                            <button
                              type="button"
                              className="btn btn-secondary w-100"
                              id="create-btn"
                              onClick={() => setCreateWalletModal(true)}
                              disabled={isLoading}
                            >
                              <i className="ri-add-line align-bottom me-1" />
                              {t('new-wallet')}
                            </button>
                          </Col>

                          <Col>
                            <button
                              type="button"
                              className="btn btn-secondary add-btn"
                              id="manage-import-incentives-btn"
                              disabled={isLoading}
                              onClick={() =>
                                setCsvImportWalletsModal(
                                  !showCsvImportWalletsModal
                                )
                              }
                            >
                              <i className="ri-file-upload-line align-bottom me-1"></i>
                              {t('massive-wallet')}
                            </button>
                          </Col>
                        </Can>

                        <Can I="manage" a="customers">
                          <Col>
                            <button
                              type="button"
                              className="btn btn-secondary w-100"
                              id="manage-blocked-wallets-btn"
                              onClick={() => setCsvStatusEditModal(true)}
                              disabled={isLoading}
                            >
                              <i className="ri-file-upload-line align-bottom me-1" />
                              {t('wallets-massive-block')}
                            </button>
                          </Col>
                        </Can>

                        <Col>
                          <ExportReport
                            className="w-100"
                            pages={meta?.pages}
                            disabled={isLoading || !customers?.length}
                            filename="Relatório OCK: Carteiras Digitais"
                            service="customers"
                            columns={[...columns, ...reportsOnlyColumns]}
                            filters={parsedFilters}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader>

                <div className="mt-3">
                  <PresetsFilter
                    items={[
                      {
                        key: 'has_available_funds',
                        label: t('wallets.presets.has_available_funds'),
                        value: {
                          amount_available: walletFilters
                            ?.find((f) => f?.field === 'amount_available')
                            ?.values?.join(',')
                            ?.replace('0', '1'),
                        },
                      },
                      {
                        key: 'has_funds_to_expire',
                        label: t('wallets.presets.has_funds_to_expire'),
                        value: {
                          amount_next_expiration: walletFilters
                            ?.find((f) => f?.field === 'amount_next_expiration')
                            ?.values?.join(',')
                            ?.replace('0', '1'),
                        },
                      },
                      {
                        key: 'has_registration_confirmed',
                        label: t('wallets.presets.has_registration_confirmed'),
                        value: { cellphone_verified: 'true' },
                      },
                      {
                        key: 'has_orders',
                        label: t('wallets.presets.has_orders'),
                        value: {
                          orders_num: walletFilters
                            ?.find((f) => f?.field === 'orders_num')
                            ?.values?.join(',')
                            ?.replace('0', '1'),
                        },
                      },
                      {
                        key: 'has_incentives',
                        label: t('wallets.presets.has_incentives'),
                        value: {
                          incentives_num: walletFilters
                            ?.find((f) => f?.field === 'incentives_num')
                            ?.values?.join(',')
                            ?.replace('0', '1'),
                        },
                      },
                    ]}
                  />
                </div>

                <CardBody className="pt-0">
                  <Can I="read" a="customers">
                    <div>
                      <TableContainer
                        isLoading={isLoading}
                        columns={columns}
                        data={customers || []}
                        activePage={searchParams.get('page')}
                        pageSize={meta?.take || 10}
                        totalPages={meta?.pages || 0}
                        count={meta?.total || 0}
                        setPage={(p) => setSearchParams('page', p)}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-0"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                      />
                    </div>
                  </Can>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Filter
        useGroups
        localePrefix="wallets"
        values={walletFilters}
        filters={filters}
      />

      <CreateDigitalWallet
        isOpen={createWalletModal}
        onSuccess={() => getData()}
        toggle={() => setCreateWalletModal(!createWalletModal)}
      />

      <EditDigitalWallet
        isOpen={editWalletModal}
        toggle={() => setEditWalletModal(!editWalletModal)}
        onSuccess={() => getData()}
        customer={editWalletData}
      />

      <WalletsImporter
        isOpen={showCsvImportWalletsModal}
        toggle={() => setCsvImportWalletsModal(!showCsvImportWalletsModal)}
        onSuccess={() => null}
      />

      <CsvStatusEdit
        isOpen={csvStatusEditModal}
        toggle={() => setCsvStatusEditModal(!csvStatusEditModal)}
        onSuccess={() => getData()}
      />

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default withTranslation()(DigitalWallets);
