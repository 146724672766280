import { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';

const RangeDatePicker = ({
  name = '',
  onClear,
  label,
  value,
  onChange,
  placeholder,
  ref = null,
  t,
  options = {},
}) => {
  const Ref = useRef(ref);
  const [hasDate, setDate] = useState(false);

  const onIconClick = () => {
    const instance = Ref.current.flatpickr;
    hasDate || value?.length ? instance.clear() : instance.toggle();
    onClear && onClear();
  };

  return (
    <>
      {label ? (
        <Label
          htmlFor="datepicker"
          className="form-label text-muted text-uppercase fw-semibold mb-3"
        >
          {label}
        </Label>
      ) : null}

      <div className="flatpickr position-relative">
        <Flatpickr
          id="datepicker"
          className="form-control position-relative"
          placeholder={placeholder ?? t('select-date')}
          value={value}
          onChange={(v) => {
            setDate(v?.length > 1);
            onChange(v);
          }}
          ref={Ref}
          options={{
            locale: Portuguese,
            dateFormat: 'd/m/Y',
            mode: 'range',
            maxDate: 'today',
            ...options,
          }}
        />

        <button
          onClick={onIconClick}
          className="border-0 m-1 p-1 bg-white position-absolute end-0 top-0 bottom-0"
          type="button"
        >
          <i
            className={`${
              !hasDate && !value?.length ? 'ri-calendar-line' : 'ri-close-line'
            } text-muted`}
          />
        </button>
      </div>
    </>
  );
};

export default withTranslation()(RangeDatePicker);
