/* eslint-disable react/prop-types */
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import useQsParams from '../../../Components/Hooks/QueryString';
import {
  getOrdersFilters,
  getSalesCardsData,
  getSalesChartData,
} from '../../../slices/manager-panel/thunk';
import React, { useEffect, useState } from 'react';
import Filter from '../components/Filter';

import Summary from './TabsContent/Summary';
import { createSelector } from 'reselect';
import { getFilters } from '../../../Components/Common/Filter';
import chartLocales from '../utils/locales';
import { isEmpty } from 'lodash';
import Billing from './TabsContent/Billing';

import NavTabRequest from '../../../Components/Common/NavTabRequest';
import SalesNumber from './TabsContent/SalesNumber';
import AverageTicket from './TabsContent/AverageTicket';
import { formatCurrency } from '../../../helpers/format';

const SalesSummary = ({ t }) => {
  const dispatch = useDispatch();
  const { searchParams } = useQsParams();
  const [, setFiltersParams] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [type, setType] = useState(searchParams.get('type'));

  document.title = `${t('results-manager.heading')} | Opencashback`;

  const filters = useSelector((state) => state.ManagerPanel.filters);

  useEffect(() => {
    dispatch(getOrdersFilters());
  }, [dispatch]);

  const { sales } = useSelector(
    createSelector(
      (state) => state.ManagerPanel,
      ({ sales }) => ({ sales })
    )
  );

  useEffect(() => {
    getData();
  }, [dispatch, searchParams.toString()]);

  useEffect(() => {
    const newType = searchParams.get('type');
    setType(newType);
  }, [searchParams]);

  useEffect(() => {
    type &&
      setChartOptions({
        chart: {
          stacked: type != 'ticket' && true,
          toolbar: { show: false },
          ...chartLocales,
        },
        legend: {
          onItemHover: { highlightDataSeries: true },
          onItemClick: { toggleDataSeries: true },
        },
        markers: { size: 5 },
        stroke: { width: [1, 1, 1] },
        colors: ['#aaff00', '#ff6400', '#0e93c2'],
        labels: sales?.chart?.data?.map((i) => i?.date),
        xaxis: {
          labels: {
            formatter: (v) => v,
          },
        },
        yaxis: [
          {
            labels: {
              formatter: (v) => {
                return type === 'sales' ? v : formatCurrency(v);
              },
            },
            tickAmount: type != 'sales' && 10,
          },
        ],
        dataLabels: {
          enabled: false,
          style: {
            colors: ['#ffffff', '#ffffff', '#ffffff'],
          },
          formatter: (v) => {
            return v > 0 ? formatCurrency(v) : '';
          },
          background: {
            enabled: true,
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          custom: ({ series, dataPointIndex, w }) => {
            const value1 = series[0][dataPointIndex] || 0;
            const value2 = series[1][dataPointIndex] || 0;
            const value3 = series[2][dataPointIndex] || 0;
            const totalSum = value1 + value2 + value3;

            return `
            <div class="" style="padding: 10px; background: rgba(255, 255, 255, 0.96); border: 1px solid #e3e3e3; box-shadow: 2px 2px 6px -4px #999; border-radius: 5px; font-size: 14px; color: #333;">
              <div class="" style="font-weight: 600; margin-bottom: 5px;">
                ${w.globals.labels[dataPointIndex]}
              </div>
              <div class="" style="display: flex; align-items: center; margin-bottom: 5px;">
                <span class="" style="background-color: #aaff00; width: 8px; height: 8px; border-radius: 50%; margin-right: 5px;"></span>
                <span>Impactado: <span style="font-weight: 500;"> ${type === 'sales' ? value1 : formatCurrency(value1)}</span></span>
              </div>
              <div class="" style="display: flex; align-items: center; margin-bottom: 5px;">
                <span class="" style="background-color: #ff6400; width: 8px; height: 8px; border-radius: 50%; margin-right: 5px;"></span>
                <span>Incremental: <span style="font-weight: 500;">${type === 'sales' ? value2 : formatCurrency(value2)}</span></span>
              </div>
              <div class="" style="display: flex; align-items: center; margin-bottom: 5px;">
                <span class="" style="background-color: #0e93c2; width: 8px; height: 8px; border-radius: 50%; margin-right: 5px;"></span>
                <span>Sem cashback: <span style="font-weight: 500;">${type === 'sales' ? value3 : formatCurrency(value3)}</span></span>
              </div>
              ${type !== 'ticket' ? `<hr style="margin: 8px 0;"><div color: #333;">Total: <span style="font-weight: 500;">${type === 'sales' ? totalSum : formatCurrency(totalSum)}</span></div>` : ``}
              </div>
          `;
          },
        },
      });
  }, [type, sales]);

  const filtersSummary = [
    { field: 'created_at' },
    { field: 'channel' },
    { field: 'store_id' },
  ];

  const getData = () => {
    const type = searchParams.get('type');
    const params = type ? { type } : {};

    const appliedFilters = getFilters(filtersSummary, [
      { field: 'created_at', type: 'between_date' },
      { field: 'channel', type: 'in' },
      { field: 'store_id', type: 'in' },
    ]);

    const storesIds = appliedFilters?.store_id;
    if (storesIds) {
      sessionStorage.setItem('filterByStores', storesIds.in[0]);
    }

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    setFiltersParams(params.filters);
    dispatch(getSalesCardsData(params));
    dispatch(getSalesChartData(params));
  };

  const buildUrlWithFilters = (baseUrl) => {
    const url = new URL(baseUrl, window.location.origin);
    const appliedFilters = [
      { field: 'created_at', value: searchParams.get('created_at') },
      { field: 'channel', value: searchParams.get('channel') },
      { field: 'store_id', value: searchParams.get('store_id') },
    ];

    appliedFilters.forEach(({ field, value }) => {
      if (value) {
        url.searchParams.append(field, value);
      }
    });
    return url.toString();
  };

  const [activeTab, setActiveTab] = useState('1');

  const tabs = [
    {
      index: '1',
      label: t('results-manager.sales-summary.titles.summary'),
      content: (
        <Summary
          sales={sales}
          setActiveTab={setActiveTab}
          buildUrlWithFilters={buildUrlWithFilters}
        />
      ),
      isActive: !searchParams.get('type'),
      value: {},
    },
    {
      index: '2',
      value: { type: 'billing' },
      label: t('results-manager.sales-summary.titles.invoicing'),
      content: (
        <Billing
          sales={sales}
          chartOptions={chartOptions}
          type={type}
          buildUrlWithFilters={buildUrlWithFilters}
        />
      ),
      isActive: searchParams.get('type') === 'billing',
    },
    {
      index: '3',
      value: { type: 'sales' },
      label: t('results-manager.sales-summary.titles.sales-number'),
      content: (
        <SalesNumber
          sales={sales}
          chartOptions={chartOptions}
          type={type}
          buildUrlWithFilters={buildUrlWithFilters}
        />
      ),
      isActive: searchParams.get('type') === 'sales',
    },
    {
      index: '4',
      value: { type: 'ticket' },
      label: t('results-manager.sales-summary.titles.average-ticket'),
      content: (
        <AverageTicket
          sales={sales}
          chartOptions={chartOptions}
          type={type}
          buildUrlWithFilters={buildUrlWithFilters}
        />
      ),
      isActive: searchParams.get('type') === 'ticket',
    },
  ];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            isLoading={filters.isLoading}
            title={t('results-manager.sales-summary.title')}
            pageTitle={t('results-manager.heading')}
          />

          <Filter filters={filters.data} />

          <NavTabRequest
            items={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(SalesSummary);
