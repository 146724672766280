/* eslint-disable react/prop-types */
import React, { } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import Card from '../../components/Card';
import { t } from 'i18next';
import StackedChartCredits from '../../components/StackedChartCredits';

const AvailableCredits = ({ credits, chartOptions, type, setActiveTab, index }) => {
  return (
    <>
      <h5 className="mb-1">{t('results-manager.credits-summary.titles.available-credits')}</h5>

      <Row>
        <Col xs={12} md={6} xl={4}>
          {credits?.cards?.available?.length ? (
            <Card
              title={t('results-manager.credits-summary.cards.total-available')}
              data={credits?.cards?.available[0]}
              icon="ri-money-dollar-circle-fill text-info"
              type={type}
              index={index}
              setActiveTab={setActiveTab}
              color="bg-info-subtle"
              customSubtitle={"Nº de créditos disponíveis: "}
              label={"Soma do valor de crédito disponível na conta."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={4}>
          {credits?.cards?.available?.length ? (
            <Card
              title={t('results-manager.credits-summary.cards.total-cashback-available')}
              data={credits?.cards?.available[1]}
              icon="ri-refund-fill text-success"
              type={type}
              index={index}
              setActiveTab={setActiveTab}
              color="bg-success-subtle"
              customSubtitle={"Nº de créditos disponíveis: "}
              label={"Soma do valor de crédito referente a vendas disponível na conta."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
        <Col xs={12} md={6} xl={4}>
          {credits?.cards?.available?.length ? (
            <Card
              title={t('results-manager.credits-summary.cards.total-incentive-available')}
              data={credits?.cards?.available[2]}
              icon="ri-gift-fill text-danger"
              type={type}
              index={index}
              setActiveTab={setActiveTab}
              color="bg-danger-subtle"
              customSubtitle={"Nº de créditos disponíveis: "}
              label={"Soma do valor de crédito referente a incentivos disponível na conta."}
            />
          ) : (
            <div className="skeleton card" style={{ height: '144px' }} />
          )}
        </Col>
      </Row>
      {
        chartOptions &&
        <StackedChartCredits credits={credits} chartOptions={chartOptions} type={type} />
      }
    </>
  );
};

export default withTranslation()(AvailableCredits);
