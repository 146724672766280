/* eslint-disable react/prop-types */
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import useQsParams from "../../../Components/Hooks/QueryString";
import { getCommunicationCardsData, getCommunicationChartsData, getCommunicationFilters } from "../../../slices/manager-panel/thunk";
import React, { useEffect, useState } from "react";

import { createSelector } from "reselect";
import { getFilters } from "../../../Components/Common/Filter";
import { isEmpty } from "lodash";
import CommunicationFilter from "../components/CommunicationFilter";
import NavTabRequest from "../../../Components/Common/NavTabRequest";
import Summary from "./TabsContent/Summary";
import Sms from "./TabsContent/Sms";
import SmsToken from "./TabsContent/SmsToken";
import Email from "./TabsContent/Email";
import Whatsapp from "./TabsContent/Whatsapp";
import chartLocales from '../utils/locales';

const CommunicationSummary = ({ t }) => {
  const dispatch = useDispatch();
  const { searchParams } = useQsParams();
  const [, setFiltersParams] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [type, setType] = useState(searchParams.get('type'));

  document.title = `${t('results-manager.heading')} | Opencashback`;

  const filters = useSelector((state) => state.ManagerPanel.filters);

  useEffect(() => {
    dispatch(getCommunicationFilters());
  }, [dispatch]);

  const { communication } = useSelector(
    createSelector(
      (state) => state.ManagerPanel,
      ({ communication }) => ({ communication })
    )
  );

  useEffect(() => {
    getData();
  }, [dispatch, searchParams.toString()]);

  useEffect(() => {
    const newType = searchParams.get('type');
    setType(newType);
  }, [searchParams]);

  useEffect(() => {
    type &&
      setChartOptions({
        chart: {
          stacked: true,
          toolbar: { show: false },
          ...chartLocales,
        },
        legend: {
          onItemHover: { highlightDataSeries: true },
          onItemClick: { toggleDataSeries: true },
        },
        markers: { size: 5 },
        stroke: { width: [1, 1, 1] },
        colors: ['#aaff00', '#ff6400', '#0e93c2'],
        labels: communication?.chart?.data?.map((i) => i?.date),
        xaxis: {
          labels: {
            formatter: (v) => v,
          },
        },
        yaxis: [
          {
            labels: {
              formatter: (v) => {
                return v;
              },
            },
            tickAmount: 10,
          },
        ],
        dataLabels: {
          enabled: false,
          style: {
            colors: ['#ffffff', '#ffffff', '#ffffff'],
          },
          formatter: (v) => {
            return v;
          },
          background: {
            enabled: true,
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          custom: ({ series, dataPointIndex, w }) => {
            const value1 = series[0][dataPointIndex] || 0;
            const value2 = series[1][dataPointIndex] || 0;
            const totalSum = value1 + value2;

            return `
            <div class="" style="padding: 10px; background: rgba(255, 255, 255, 0.96); border: 1px solid #e3e3e3; box-shadow: 2px 2px 6px -4px #999; border-radius: 5px; font-size: 14px; color: #333;">
              <div class="" style="font-weight: 600; margin-bottom: 5px;">
                ${w.globals.labels[dataPointIndex]}
              </div>
              <div class="" style="display: flex; align-items: center; margin-bottom: 5px;">
                <span class="" style="background-color: #aaff00; width: 8px; height: 8px; border-radius: 50%; margin-right: 5px;"></span>
                <span>Sucesso: <span style="font-weight: 500;"> ${value1}</span></span>
              </div>
              <div class="" style="display: flex; align-items: center; margin-bottom: 5px;">
                <span class="" style="background-color: #ff6400; width: 8px; height: 8px; border-radius: 50%; margin-right: 5px;"></span>
                <span>Erro: <span style="font-weight: 500;">${value2}</span></span>
              </div>
            
              <hr style="margin: 8px 0;">
                <div color: #333;">
                  Total: 
                  <span style="font-weight: 500;">${totalSum}</span>
                </div>
              </hr>
            </div>
          `;
          },
        },
      });
  }, [type, communication]);

  const filtersSummary = [
    { field: 'created_at' },
  ];

  const getData = () => {
    const type = searchParams.get('type');
    const params = type ? { type } : {};

    const appliedFilters = getFilters(filtersSummary, [
      { field: 'created_at', type: 'between_date' },
    ]);

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    setFiltersParams(params.filters);
    dispatch(getCommunicationCardsData(params));
    dispatch(getCommunicationChartsData(params));
  };

  const buildUrlWithFilters = (baseUrl) => {
    const url = new URL(baseUrl, window.location.origin);
    const appliedFilters = [
      { field: 'created_at', value: searchParams.get('created_at') },
      { field: 'channel', value: searchParams.get('channel') },
      { field: 'store_id', value: searchParams.get('store_id') },
    ];

    appliedFilters.forEach(({ field, value }) => {
      if (value) {
        url.searchParams.append(field, value);
      }
    });
    return url.toString();
  };

  const [activeTab, setActiveTab] = useState('1');

  const tabs = [
    {
      index: '1',
      label: t('results-manager.communication-summary.titles.general'),
      content: <Summary communication={communication} setActiveTab={setActiveTab} buildUrlWithFilters={buildUrlWithFilters} />,
      isActive: !searchParams.get('type'),
      value: {},
    },
    {
      index: '2',
      label: t('results-manager.communication-summary.titles.sms'),
      content: <Sms communication={communication} chartOptions={chartOptions} type={type} buildUrlWithFilters={buildUrlWithFilters} />,
      isActive: searchParams.get('type') === 'sms',
      value: { type: 'sms' },
    },
    {
      index: '3',
      label: t('results-manager.communication-summary.titles.sms-token'),
      content: <SmsToken communication={communication} chartOptions={chartOptions} type={type} buildUrlWithFilters={buildUrlWithFilters} />,
      isActive: searchParams.get('type') === 'smsToken',
      value: { type: 'smsToken' },
    },
    {
      index: '4',
      label: t('results-manager.communication-summary.titles.email'),
      content: <Email communication={communication} chartOptions={chartOptions} type={type} buildUrlWithFilters={buildUrlWithFilters} />,
      isActive: searchParams.get('type') === 'email',
      value: { type: 'email' },
    },
    {
      index: '5',
      label: t('results-manager.communication-summary.titles.whatsapp'),
      content: <Whatsapp communication={communication} chartOptions={chartOptions} type={type} buildUrlWithFilters={buildUrlWithFilters} />,
      isActive: searchParams.get('type') === 'whatsapp',
      value: { type: 'whatsapp' },
    },
  ];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            isLoading={filters.isLoading}
            title={t("results-manager.communication-summary.title")}
            pageTitle={t("results-manager.heading")}
          />

          <CommunicationFilter filters={filters.data} />

          <NavTabRequest items={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

        </Container>
      </div>
    </>
  );
};

export default withTranslation()(CommunicationSummary);

