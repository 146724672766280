import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import SwiperCore from 'swiper';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import RuleItem from './RuleItem';
import { useBreakpoints } from '../../../Components/Hooks/BreakpointHooks';

const TransactionalOrder = ({ t, transactionals }) => {
  SwiperCore.use([FreeMode, Navigation, Thumbs]);

  const events = [
    { name: 'purchase-made', rules: [] },
    { name: 'credit-to-receive', rules: [] },
    { name: 'credit-received', rules: [] },
    { name: 'credit-to-expire', options: { days: 30 }, rules: [] },
    { name: 'credit-to-expire', options: { days: 15 }, rules: [] },
    { name: 'credit-to-expire', options: { days: 7 }, rules: [] },
    { name: 'credit-to-expire', options: { days: 1 }, rules: [] },
  ];

  const [thumbsSwiper] = useState(null);
  const [orderTransactionals, setOrderTransactionals] = useState(events);
  const { isLg } = useBreakpoints();

  useEffect(() => {
    setOrderTransactionals(
      events.map((event) => {
        const eventName = event.options?.days
          ? `${event.name}-${event.options.days}-days`
          : event.name;
        const transactionalsEvent = transactionals.filter((tr) =>
          tr.options?.days
            ? `${tr.transactional}-${tr.options.days}-days` === eventName
            : tr.transactional === eventName
        );

        return { ...event, name: eventName, rules: transactionalsEvent };
      })
    );
  }, [transactionals]);

  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col lg={12}>
          <div>
            <div className="horizontal-timeline my-3">
              <Swiper
                className="timelineSlider"
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                slidesPerView={isLg ? 4 : 'auto'}
              >
                <div className="swiper-wrapper">
                  {orderTransactionals.map((item, key) => (
                    <SwiperSlide key={key}>
                      <RuleItem
                        rules={item.rules}
                        active={item.active}
                        event={item.name}
                        transactionalType="order"
                      />
                    </SwiperSlide>
                  ))}
                </div>
              </Swiper>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(TransactionalOrder);
