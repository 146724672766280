import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { isInvalid } from '../../helpers/functions';
import CurrencyInput from '../../Components/Common/CurrencyInput';
import { useFormik } from 'formik';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer } from '../../slices/customers/thunk';
import CreateDigitalWallet from '../DigitalWallets/Create';
import Yup from '../../helpers/yup_extras';
import { currencyFieldToFloat } from '../../helpers/functions';
import { IMaskInput } from 'react-imask';

const FirstTab = ({ t, onSubmit, reset }) => {
  const dispatch = useDispatch();

  const [document, setDocument] = useState('');

  useEffect(() => {
    if (reset) {
      validation.resetForm();
      setPaymentMethod([]);
      setIsLoading(false);
    }
  }, [reset]);

  const paymentMethodsOptions = [
    { label: t('payment-methods-money'), value: 'money' },
    { label: t('payment-methods-debit-card'), value: 'debit-card' },
    { label: t('payment-methods-credit-card'), value: 'credit-card' },
    { label: t('payment-methods-pix'), value: 'pix' },
    { label: t('payment-methods-other'), value: 'other' },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethod] = useState([]);
  const [createCustomerModal, setCreateCustomerModal] = useState(false);

  const cleanDocument = (value) =>
    value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');

  const onChangePaymentMethod = (e) => {
    if (e.target.checked) {
      return setPaymentMethod([...paymentMethods, e.target.value]);
    }

    setPaymentMethod(paymentMethods.filter((item) => item !== e.target.value));
  };

  const onCreateCustomer = async (values) => {
    setCreateCustomerModal(false);
    setIsLoading(true);

    const customer_data = await dispatch(
      getCustomer({ document: values?.customer?.document })
    );
    validation.resetForm();
    setIsLoading(false);

    return onSubmit({
      ...customer_data.payload,
      total: validation.values.total,
    });
  };

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      document: '',
      total: '',
    },
    validationSchema: Yup.object({
      document: Yup.string().when('accountSettings.document_type', {
        is: 'document_br',
        then: (schema) =>
          schema
            .required(t('validation-required-document'))
            .document(t('validation-invalid-document')),
        otherwise: (schema) => schema.notRequired(),
      }),
      total: Yup.string()
        .required(t('validation-required-order-total'))
        .test(
          'is-greater-than-zero',
          t('validation-min-order-total'),
          (value) => {
            return currencyFieldToFloat(value) > 0;
          }
        ),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const customerDocument = cleanDocument(values.document);
      const customer_data = await dispatch(
        getCustomer({ document: customerDocument })
      );

      if (!customer_data.payload) {
        setIsLoading(false);
        return setCreateCustomerModal(true);
      }

      setIsLoading(false);
      validation.resetForm();
      return onSubmit({ ...customer_data.payload, total: values.total });
    },
  });

  const handleChangeDocument = (e) => {
    validation.handleChange(e);
    setDocument(e.target.value);
  };

  const accountSettings = useSelector(
    (state) => state.GeneralSettings.accountSettings
  );

  return (
    <React.Fragment>
      <Row className="justify-content-center py-5">
        <Col xs={12} sm={6} lg={5}>
          <Row>
            <Form
              className="tablelist-form"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="name-field"
                        className="form-label text-muted text-uppercase fw-semibold"
                      >
                        {t('document')}
                      </Label>
                      <div className="input-group">
                        <IMaskInput
                          mask={
                            accountSettings?.document_type == 'document_br'
                              ? [
                                  {
                                    mask: '000.000.000-00',
                                    maxLength: 11,
                                  },
                                  {
                                    mask: '00.000.000/0000-00',
                                    maxLength: 14,
                                  },
                                ]
                              : /^[\w\W]*$/
                          }
                          unmask={true}
                          name="document"
                          id="document-field"
                          className={classnames('form-control', {
                            'is-invalid': isInvalid(validation, 'document'),
                          })}
                          placeholder={t('document-placeholder')}
                          type="text"
                          onAccept={(value, mask) =>
                            handleChangeDocument({
                              target: {
                                name: 'document',
                                value: mask.value,
                              },
                            })
                          }
                          value={validation.values.document || ''}
                          inputRef={(el) => {
                            if (el) {
                              el.addEventListener(
                                'blur',
                                validation.handleBlur
                              );
                            }
                          }}
                        />
                        {isInvalid(validation, 'document') && (
                          <FormFeedback type="invalid">
                            {validation.errors.document}
                          </FormFeedback>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="total-field"
                        className="form-label text-muted text-uppercase fw-semibold"
                      >
                        {t('order-total')}
                      </Label>
                      <div className="input-group">
                        <span className="input-group-text">
                          {t('currency')}
                        </span>
                        <CurrencyInput
                          name="total"
                          id="total-field"
                          placeholder="0,00"
                          onChange={(e) => validation.setFieldValue('total', e)}
                          onBlur={validation.handleBlur}
                          value={validation.values.total}
                          invalid={isInvalid(validation, 'total')}
                        />
                        {isInvalid(validation, 'total') ? (
                          <FormFeedback type="invalid">
                            {validation.errors.total}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="mb-3">
                    <Label
                      htmlFor="status-select"
                      className="form-label text-muted text-uppercase fw-semibold"
                    >
                      {t('payment-methods')}
                    </Label>
                    <Row>
                      {paymentMethodsOptions.map((item, key) => (
                        <Col sm={12} lg={6} key={key}>
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id={`checkbox-payment-${key}`}
                              defaultValue={item.value}
                              onChange={onChangePaymentMethod}
                              checked={paymentMethods.includes(item.value)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor={`checkbox-payment-${key}`}
                            >
                              {item.label}
                            </Label>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-grid gap-2 mt-3">
                      <Button
                        type="submit"
                        className="btn btn-tertiary text-white"
                        // onClick={ }
                        disabled={isLoading}
                      >
                        {t('start-order')}
                        {isLoading && <Spinner size="sm" className="ms-2" />}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Row>
        </Col>
      </Row>
      <CreateDigitalWallet
        origin="order"
        toggle={() => setCreateCustomerModal(!createCustomerModal)}
        isOpen={createCustomerModal}
        document={document}
        onSuccess={onCreateCustomer}
        isCellphoneMandatory={true}
        saveButtonTitle={t('order-step')}
      />
    </React.Fragment>
  );
};

export default withTranslation()(FirstTab);
