/* eslint-disable react/prop-types */
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Row, Col, Card as CardWrapper, CardBody } from 'reactstrap';

export default function StackedChart({ sales, chartOptions, type }) {
  return (
    <Row>
      <Col>
        <CardWrapper>
          <CardBody>
            {!sales?.chart?.isLoading ? (
              <ReactApexChart
                key={type}
                type="bar"
                height={550}
                options={chartOptions}
                series={[
                  {
                    name: 'Impactado',
                    type: 'bar',
                    data: sales?.chart?.data?.map((i) => i?.value1),
                  },
                  {
                    name: 'Incremental',
                    type: 'bar',
                    data: sales?.chart?.data?.map((i) => i?.value2),
                  },
                  {
                    name: 'Sem cashback',
                    type: 'bar',
                    data: sales?.chart?.data?.map((i) => i?.value3),
                  },
                ]}
              />
            ) : (
              <div className="skeleton card" style={{ height: 550 }} />
            )}
          </CardBody>
        </CardWrapper>
      </Col>
    </Row>
  );
}
